import PropTypes from 'prop-types';

const ArrowRightIcon = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 3.41699L9.33333 7.50033L5.25 11.5837"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
};

export { ArrowRightIcon };
