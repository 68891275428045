import { useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';

import { TABLE_TYPE } from 'constants/table';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import { useUI } from 'context/ui.context';
import PopoverArrowIcon from 'assets/icons/PopoverArrowIcon';
import Arrow from 'assets/icons/Arrow';
import { PAGE_PRODUCT } from 'constants/pages';
import { DropdownColorsIcon } from 'assets/icons/DropdownColorsIcon';
import useClickOutside from 'hooks/useClickOutside';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useAnalyticType } from 'context/analytic.type.context';
import { TABLE_INNER_TYPE } from 'constants';
import { useFetchFilteredInnerData } from 'hooks/useFetchFilteredInnerData';
import { COLORS } from 'utils/colors';
import { useChartDoughnut } from 'context/chart.doughnut.context';
import { useProduct } from '../../../context/product.context';

export const ProductColorLabel = () => {
  const history = useHistory();
  const ref = useRef(null);

  const { defaultColors } = useChartDoughnut();

  const { isInner } = useAnalyticType();
  const { id } = useParams();
  const { isMobile } = useUI();
  const [showFullColorList, setShowFullColorList] = useState(false);
  const { productData } = useProduct();
  let productSalesData;
  const { data: productOuterSalesData } = useFetchFilteredProductData({
    type: isInner ? TABLE_INNER_TYPE.PRODUCT : TABLE_TYPE.PRODUCT_SALES,
    id,
    enabled: !!id && !isInner,
  });
  const { data: productInnerSalesData } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.PRODUCT,
    data: { id },
    enabled: !!id && isInner,
  });
  productSalesData = isInner ? productInnerSalesData : productOuterSalesData;

  const { data: productColorsData } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_COLORS,
    id,
    enabled: !!id && !isInner,
  });

  const colors = productColorsData?.all_data || {};
  const hasColorsPopup = Boolean(Object.keys(colors)?.length > 1);
  const { setError } = useFilterReducer();

  if (productSalesData?.error) {
    setError(productSalesData.error);
  }

  const color = isInner
    ? productSalesData?.color
    : productSalesData?.notPaginated?.colors?.[0] !== '-' &&
      productSalesData?.notPaginated?.colors?.join(', ');

  const stock = isInner
    ? productSalesData?.quantity
    : /*productSalesData?.notPaginated?.stock*/ /*Object.values(colors).reduce((prev, current) => +(current.stock_qty || 0) + prev, 0)*/ productData?.stock ||
      0;
  const handleOpenProduct = (productId) => () => {
    history.push(`${PAGE_PRODUCT}/${productId}`);
    setShowFullColorList(false);
  };

  const isShouldHaveHover = Object.keys(colors).length > 1;

  useClickOutside(ref, () => setShowFullColorList(false));
  let indexOfDefault = 0;
  return (
    <>
      {(color || stock >= 0) && (
        <div className="relative" ref={ref}>
          <div
            className={cn(
              'transition-colors border border-gray leading-none text-gray-400 py-2 px-3 font-normal text-sm rounded-full flex items-center min-w-max relative group',
              {
                'cursor-pointer': hasColorsPopup,
                'border-green': showFullColorList,
                ['hover:border-green']: isShouldHaveHover,
              },
            )}
            onClick={
              hasColorsPopup ? () => setShowFullColorList((prevOpen) => !prevOpen) : undefined
            }
          >
            {color && (
              <>
                <span className="mr-2">Цвет</span>
                <span
                  className={cn('transition-colors text-black', {
                    ['group-hover:text-green']: isShouldHaveHover,
                  })}
                >
                  {color}
                </span>
              </>
            )}
            {color && stock >= 0 && (
              <div className="mx-2 flex items-center justify-center">
                <span
                  className={cn(
                    'border-1 bg-black rounded-full h-[2px] w-[2px] transition-colors',
                    {
                      ['group-hover:bg-green']: isShouldHaveHover,
                    },
                  )}
                />
              </div>
            )}
            {stock >= 0 && (
              <div
                className={cn('transition-colors text-black', {
                  ['group-hover:text-green']: isShouldHaveHover,
                })}
              >
                Остаток&nbsp;{stock.toLocaleString('ru')}&nbsp;шт
              </div>
            )}
            {hasColorsPopup && (
              <Arrow
                strokeWidth={1}
                className={cn('ml-2 transition-all', {
                  ['rotate-[270deg]']: showFullColorList,
                  ['rotate-90']: !showFullColorList,
                })}
              />
            )}
          </div>
          {showFullColorList && (
            <>
              {isMobile && <div className="fixed w-full h-full z-38 bg-black opacity-25 left-0" />}
              <div
                className={cn(
                  'bottom-0 fixed w-full left-0 bg-white z-40 lg:bottom-unset lg:absolute lg:top-10 lg:right-0 lg:pb-0 lg:rounded lg:left-auto lg:bottom-auto lg:w-96',
                )}
                style={{
                  filter:
                    'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1))',
                }}
              >
                {!isMobile && (
                  <PopoverArrowIcon
                    className="absolute inset-x-2/4 -top-2.5"
                    style={{ transform: 'translate(-50%, -50%)', top: '-3px' }}
                  />
                )}
                <div className="pt-4 px-4">
                  <div className="flex gap-2 border-b pb-4">
                    <div className="flex flex-1 gap-2 items-center lg:max-w-40">
                      <DropdownColorsIcon fill="none" className="h-4 w-4" />
                      Цвет
                    </div>
                    <div className="flex flex-1 max-w-24">Остатки</div>
                    <div className="">Заказы</div>
                  </div>
                </div>
                {Object.entries(colors)
                  .sort(([, a], [, b]) => Number(b.orders_rub) - Number(a.orders_rub))
                  .map(([productId, colorInfo]) => {
                    let resultColor;
                    if (colorInfo.colors?.[0] in COLORS) {
                      resultColor = COLORS[colorInfo.colors?.[0]];
                    } else {
                      resultColor = defaultColors[indexOfDefault];
                      indexOfDefault++;
                    }
                    return (
                      <div
                        key={productId}
                        className="flex gap-2 py-2 px-4 hover:text-green hover:bg-green-200 cursor-pointer"
                        onClick={handleOpenProduct(productId)}
                      >
                        <div
                          className={cn(' flex items-center flex-1 lg:flex-none lg:w-40', {
                            'font-bold': id === productId,
                          })}
                        >
                          <div className="relative self-center flex rounded-full z-50, mr-1.5 mt-0.5">
                            <div
                              className={'rounded-full'}
                              style={{
                                minWidth: 8,
                                height: 8,
                                backgroundColor: resultColor,
                              }}
                            />
                            <div
                              className="w-2 h-2 rounded-full flex-none absolute top-0"
                              style={{ border: `1px solid rgba(0, 0, 0, 0.1)` }}
                            />
                          </div>
                          <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                            {colorInfo.colors?.join(', ') || '-'}
                          </div>
                          {isMobile && <Arrow className="ml-2" strokeWidth={1} />}
                        </div>
                        <div className="text-right w-28">
                          {' '}
                          {colorInfo.stock_qty
                            ? `${Number(colorInfo.stock_qty).toLocaleString('ru')} шт`
                            : '-'}
                        </div>
                        <div className="text-right w-32 whitespace-nowrap">
                          {' '}
                          {typeof colorInfo.orders_rub === 'number'
                            ? `${colorInfo.orders_rub.toLocaleString('ru')} ₽`
                            : '-'}
                        </div>
                      </div>
                    );
                  })}
                {isMobile && <button className="button-primary w-full">Закрыть</button>}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
