import { IconType } from 'types/icon.type';

const CalendarIcon = ({ width = 20, height = 21, ...otherProps }) => {
  return (
    <svg
      width={width}
      height={height}
      {...otherProps}
      fill="none"
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.75 11.5c0-1.408 0-2.427.055-3.232.055-.798.16-1.332.345-1.777A5.25 5.25 0 0 1 3.99 3.65c.446-.185.98-.29 1.778-.345C6.573 3.25 7.592 3.25 9 3.25h2c1.408 0 2.427 0 3.232.055.798.055 1.332.16 1.777.345a5.25 5.25 0 0 1 2.841 2.84c.185.446.29.98.345 1.778.055.805.055 1.824.055 3.232 0 1.408 0 2.427-.055 3.232-.055.798-.16 1.332-.345 1.777a5.25 5.25 0 0 1-2.84 2.841c-.446.185-.98.29-1.778.345-.805.055-1.824.055-3.232.055H9c-1.408 0-2.427 0-3.232-.055-.798-.055-1.332-.16-1.777-.345a5.25 5.25 0 0 1-2.841-2.84c-.185-.446-.29-.98-.345-1.778C.75 13.927.75 12.908.75 11.5ZM15.447 9.249H4.553"
        stroke="currentColor"
        fill="#fff"
      />
      <path
        d="M6 5.965a.8.8 0 0 0 .8-.8V1.802a.8.8 0 0 0-1.6 0v3.363a.8.8 0 0 0 .8.8ZM14 5.965a.8.8 0 0 0 .8-.8V1.802a.8.8 0 0 0-1.6 0v3.363a.8.8 0 0 0 .8.8Z"
        stroke="currentColor"
        fill="#fff"
      />
    </svg>
  );
};

CalendarIcon.propTypes = IconType;

export { CalendarIcon };
