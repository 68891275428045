import React, { useState } from 'react';
import { tariffsConstants } from 'constants/tariffs';

const TariffsContext = React.createContext();
TariffsContext.displayName = 'Tariffs.Context';

const TariffsProvider = (props) => {
  const [isOpenTariffsModal, setIsOpenTariffsModal] = useState(false);
  const [tariff, setTariff] = useState(1);
  const [period, setPeriod] = useState(1);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const tariffsData = tariffsConstants[period];
  const activeTariffData = tariffsData?.[tariff];

  const periodsData = [
    {
      value: 1,
      title: 'Месяц',
    },
    {
      value: 2,
      title: '3 мес',
      sale: '-10%',
    },
    {
      value: 3,
      title: '6 мес',
      sale: '-20%',
    },
    {
      value: 4,
      title: '12 мес',
      sale: '-30%',
    },
    {
      value: 5,
      title: '24 мес',
      sale: '-40%',
    },
  ];

  const openTariffsModal = ({ tariff, period }) => {
    setIsOpenTariffsModal(true);

    tariff !== undefined && setTariff(tariff);
    period !== undefined && setPeriod(period);
  };

  const closeTariffsModal = () => {
    setIsOpenTariffsModal(false);
  };

  const handlePeriod = (period) => {
    setPeriod(period);
  };

  const handleTariff = (tariff) => {
    setTariff(tariff);
  };

  const value = {
    activePeriod: period,
    activeTariff: tariff,
    handlePeriod,
    handleTariff,
    isOpenTariffsModal,
    openTariffsModal,
    closeTariffsModal,
    tariffsData,
    activeTariffData,
    periodsData,
    expandedKeys,
    setExpandedKeys,
  };

  return <TariffsContext.Provider value={value} {...props} />;
};

const useTariffs = () => {
  return React.useContext(TariffsContext);
};

export { TariffsProvider, useTariffs };
