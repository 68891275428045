import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import crossImage from 'assets/images/crossTariffs.svg';
import checkImage from 'assets/images/checkedTariffs.svg';

import Popover from 'components/Popover';
import { TariffItemShape } from './';
import { useUI } from 'context/ui.context';
import { ArrowRightIcon } from 'assets/icons/ArrowRightIcon';
import { useTariffs } from 'context/tariffs.context';

const Item = ({ text, check, className = 'mb-2' }) => {
  return (
    <div className={cn('flex text-sm', className)}>
      <div className={!check ? 'text-gray-400' : ''}>{text}</div>
      <div className="border-dashed border-gray-300 border-b flex-1 mx-3 h-4" />
      <img
        alt={check ? 'Check Icon' : 'Cross Icon'}
        className="w-5"
        src={check ? checkImage : crossImage}
      />
    </div>
  );
};

Item.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  check: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const MobileItem = ({ text, tooltip, className = 'mb-2', check }) => {
  const buttonRef = useRef();
  // const checkBasic = checkAll || tariffsData[tariffsEnum.BASIC][field];
  // const checkPro = checkAll || tariffsData[tariffsEnum.PRO][field];
  // const resultCheck = item.title === 'Basic' ? checkBasic : checkPro;
  // console.log(item.title, field, resultCheck)
  return (
    <div
      className={cn('grid text-base', className)}
      style={{
        gridTemplateColumns: '10fr 1fr',
      }}
    >
      <div className="flex whitespace-nowrap">
        {text}
        <div className="flex items-center">
          <button ref={buttonRef} className="ml-1.5">
            <svg
              width="20"
              height="20"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.4">
                <path
                  d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                  fill="white"
                  stroke="#6E6E6E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.60938 5.54998C5.76219 5.11556 6.06382 4.74925 6.46085 4.51592C6.85787 4.28258 7.32466 4.19729 7.77854 4.27514C8.23242 4.353 8.64411 4.58897 8.94068 4.94127C9.23724 5.29358 9.39956 5.73947 9.39887 6.19998C9.39887 7.49998 7.44887 8.14998 7.44887 8.14998"
                  stroke="#6E6E6E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 10.75H7.507"
                  stroke="#6E6E6E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </button>
          <Popover
            tooltipRef={buttonRef}
            tooltip={tooltip || ''}
            bgColor="gray-900"
            color="white"
            arrow={true}
          />
        </div>
        <div className="border-dashed border-b flex-1" />
      </div>
      <div className="flex justify-end">
        <img
          alt={check ? 'Check Icon' : 'Cross Icon'}
          className="w-5"
          src={check ? checkImage : crossImage}
        />
      </div>
    </div>
  );
};

MobileItem.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  field: PropTypes.string,
  checkAll: PropTypes.bool,
  className: PropTypes.string,
  check: PropTypes.any,
};

const Section = ({ section, isExpanded: expanded, expandedKeys, setExpandedKeys }) => {
  const { label, expandable, details } = section;
  const handleExpand = () => {
    if (expanded) {
      const newKeys = [...expandedKeys];
      const index = newKeys.indexOf(label);
      newKeys.splice(index, 1);
      setExpandedKeys(newKeys);
    } else {
      const newKeys = [...expandedKeys];
      newKeys.push(label);
      setExpandedKeys(newKeys);
    }
  };
  return (
    <div className="border border-black/12 rounded-lg p-6">
      <div
        onClick={() => {
          if (expandable && details.filter((el) => el.offered).length !== 0) {
            handleExpand();
          }
        }}
        className="cursor-pointer"
      >
        <div className="flex items-center justify-between">
          <span
            className={`block ${
              expandable && expanded ? 'text-[#00745F]' : 'text-[#333D37]'
            } font-bold text-lg leading-6`}
          >
            {label}
          </span>
          {expandable && details.filter((el) => el.offered).length !== 0 && (
            <ArrowRightIcon
              width="24"
              height="24"
              className={!expanded ? 'rotate-90 text-[#6E6E6E]/60' : '-rotate-90 text-[#00745F]'}
            />
          )}
        </div>
        {expandable && (
          <div className="flex space-x-2 mt-1.5">
            <span className="block text-[#6E6E6E] text-xs leading-[13px] relative top-[7px]">
              {'Доступно инструментов'.toUpperCase()}
            </span>
            <span
              className={cn('block text-[#00745F] text-base font-medium relative', {
                '!text-[#A26D22]': details.filter((el) => el.offered).length === 0,
              })}
            >{`${details.filter((el) => el.offered).length} из ${details.length}`}</span>
          </div>
        )}
      </div>
      {expanded && details.filter((el) => el.offered).length !== 0 && (
        <div className="mt-6">
          {details.map((item, index) => {
            return <Row text={item.name} check={item.offered} checkAll item={item} key={index} />;
          })}
        </div>
      )}
    </div>
  );
};

const SectionShape = PropTypes.shape({
  label: PropTypes.string,
  expandable: PropTypes.bool,
  details: PropTypes.array,
}).isRequired;

Section.propTypes = {
  section: SectionShape,
  isExpanded: PropTypes.bool,
  expandedKeys: PropTypes.array,
  setExpandedKeys: PropTypes.func.isRequired,
};

const Row = ({ text, tooltip, check, checkAll, className = 'mb-2' }) => {
  const { isMobile } = useUI();

  return (
    <>
      {isMobile ? (
        <MobileItem
          text={text}
          checkAll={checkAll}
          className={className}
          tooltip={tooltip}
          check={check}
        />
      ) : (
        <Item text={text} check={check} className={className} tooltip={tooltip} />
      )}
    </>
  );
};

Row.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  check: PropTypes.bool.isRequired,
  checkAll: PropTypes.bool,
  className: PropTypes.string,
  item: PropTypes.any,
};

export const List = ({ item }) => {
  const { expandedKeys, setExpandedKeys } = useTariffs();
  const { sections = [] } = item || {};
  return (
    <div className="space-y-3">
      {sections.map((section) => (
        <Section
          section={section}
          key={section.label}
          expandedKeys={expandedKeys}
          isExpanded={!section.expandable || expandedKeys.includes(section.label)}
          setExpandedKeys={setExpandedKeys}
        />
      ))}
    </div>
  );
};

List.propTypes = {
  item: TariffItemShape,
  items: PropTypes.object,
  disabledButton: PropTypes.bool,
  recommend: PropTypes.bool,
  onClick: PropTypes.func,
  modalView: PropTypes.bool,
};
