import React, { Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { useUI } from 'context/ui.context';
import { PeriodButton, TariffRadioList, TariffColumnList } from './';
import { useTariffs } from 'context/tariffs.context';

export const Columns = ({ modalView }) => {
  const { isMobile } = useUI();
  const { activePeriod, handlePeriod, periodsData } = useTariffs();

  return (
    <>
      {!modalView && (
        <h1 className="text-xl font-bold pb-5 lg: mt-2 lg:mt-7 lg:text-2xl lg:mt-14">Тарифы</h1>
      )}
      <div
        className={cn('px-3 lg:px-0', {
          'bg-gray-50 border-t pt-5 lg:pt-8': !modalView,
        })}
        style={{
          width: isMobile ? 'calc(100% + 1.5rem)' : 'calc(100% + 4rem)',
          marginLeft: isMobile ? '-0.75rem' : '-2rem',
        }}
      >
        <div className="flex justify-center">
          {periodsData.map((item) => (
            <Fragment key={`period-${item.value}`}>
              <PeriodButton
                selected={activePeriod === item.value}
                onClick={() => handlePeriod(item.value)}
              >
                {item.title}
                {item.sale && (
                  <div
                    className={cn(
                      'italic lg:not-italic ml-1 lg:ml-0 block text-sm lg:relative lg:bottom-3 lg:right-0 group-hover:text-white ',
                      {
                        '!text-white': activePeriod === item.value,
                        'text-red-500': activePeriod !== item.value,
                      },
                    )}
                  >
                    {item.sale}
                  </div>
                )}
              </PeriodButton>
            </Fragment>
          ))}
        </div>
        {isMobile && modalView ? (
          <>
            <TariffRadioList />
          </>
        ) : (
          <TariffColumnList modalView={modalView} />
        )}
      </div>
    </>
  );
};

Columns.propTypes = {
  modalView: PropTypes.bool,
};
