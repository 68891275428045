import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TariffColumn } from './';
import { useTariffs } from 'context/tariffs.context';
import { usePayment } from 'context/payment.context';
import { tariffExtWB, tariffsEnum } from 'constants/tariffs';

export const TariffColumnList = ({ modalView }) => {
  const { tariffsData } = useTariffs();
  const { onSelectTariff } = usePayment();

  const getPlugin = () => {
    window.open(
      'https://chromewebstore.google.com/detail/wecheck/nbhkkkflcfheikkjmcphbfhbleblccgl',
      '_blank',
    );
  };

  return (
    <div className="max-w-7xl mx-auto lg:mt-8 lg:flex justify-center py-10 -mb-4">
      {Object.keys(tariffsData).map((key) => (
        <Fragment key={key}>
          <TariffColumn
            item={tariffsData[key]}
            onSubmit={() => onSelectTariff(key)}
            modalView={modalView}
            highlight={+key === +tariffsEnum.PRO}
          />
        </Fragment>
      ))}

      <TariffColumn item={tariffExtWB} modalView={modalView} onSubmit={getPlugin} />
    </div>
  );
};

TariffColumnList.propTypes = {
  modalView: PropTypes.bool,
};
