const detailArrayBasic = [
  {
    label: 'Ниши и конкуренты',
    expandable: true,
    details: [
      { name: 'Аналитика по Товарам', offered: true },
      { name: 'Аналитика по Продавцам', offered: true },
      { name: 'Аналитика по Категориям', offered: true },
      { name: 'Аналитика по Складам и Регионам', offered: true },
      { name: 'Аналитика по Цветам и Размерам', offered: true },
      { name: 'Трекер Позиций', offered: true },
      { name: 'Ценовые Сегменты', offered: true },
      { name: 'Динамика и Тренды', offered: true },
      { name: 'Аналитика Новинок', offered: true },
      { name: 'Потенциал и Упущенные заказы', offered: true },
      { name: 'Похожие Товары', offered: true },
      { name: 'Изменения Товаров', offered: true },
      { name: 'Качество Контента', offered: true },
      { name: 'Аналитика по Брендам', offered: true },
      { name: 'Избранное', offered: true },
    ],
  },
  {
    label: 'SEO-продвижение',
    expandable: true,
    details: [
      { name: 'Подбор ключевых слов', offered: false },
      { name: 'Подбор слов по подсказкам', offered: false },
      { name: 'Сравнение товаров', offered: false },
      { name: 'Трекер позиций', offered: false },
      { name: 'Мониторинг товаров', offered: false },
      { name: 'Позиции товаров', offered: false },
      { name: 'Умный подбор слов', offered: false },
      { name: 'Умное описание товаров', offered: false },
    ],
  },
  {
    label: 'Управление продажами',
    expandable: true,
    details: [
      { name: 'Ежедневная динамика', offered: false },
      { name: 'Аналитика по товарам', offered: false },
      { name: 'Аналитика заказов возвратов и продаж', offered: false },
      { name: 'Аналитика финансовых показателей', offered: false },
      { name: 'Аналитика по складам', offered: false },
      { name: 'Аналитика по размерам', offered: false },
      { name: 'Планирование поставок', offered: false },
      { name: 'Потенциал и Упущенные заказы', offered: false },
      { name: 'ABC Анализ', offered: false },
      { name: 'Трекер позиций', offered: false },
      { name: 'Похожие товары', offered: false },
      { name: 'Управление ценами', offered: false },
      { name: 'Товары требующие внимания', offered: false },
      { name: 'Панель экспресс анализа бизнеса', offered: false },
      { name: 'Еженедельные отчеты', offered: false },
    ],
  },
  // {
  //   label: 'Реклама',
  //   expandable: true,
  //   details: [
  //     { name: "Проверка рекламных ставок", offered: true },
  //   ]
  // }
];

const basicConstants = {
  title: 'Lite',
  dataLengthText: '30 дней',
  dataPeriod: '2 года',
  sections: detailArrayBasic,
};

const detailArrayPro = [
  {
    label: 'Ниши и конкуренты',
    expandable: true,
    details: [
      { name: 'Аналитика по Товарам', offered: true },
      { name: 'Аналитика по Продавцам', offered: true },
      { name: 'Аналитика по Категориям', offered: true },
      { name: 'Аналитика по Складам и Регионам', offered: true },
      { name: 'Аналитика по Цветам и Размерам', offered: true },
      { name: 'Трекер Позиций', offered: true },
      { name: 'Ценовые Сегменты', offered: true },
      { name: 'Динамика и Тренды', offered: true },
      { name: 'Аналитика Новинок', offered: true },
      { name: 'Потенциал и Упущенные заказы', offered: true },
      { name: 'Похожие Товары', offered: true },
      { name: 'Изменения Товаров', offered: true },
      { name: 'Качество Контента', offered: true },
      { name: 'Аналитика по Брендам', offered: true },
      { name: 'Избранное', offered: true },
    ],
  },
  {
    label: 'SEO-продвижение',
    expandable: true,
    details: [
      { name: 'Подбор ключевых слов', offered: true },
      { name: 'Подбор слов по подсказкам', offered: true },
      { name: 'Сравнение товаров', offered: true },
      { name: 'Трекер позиций', offered: true },
      { name: 'Мониторинг товаров', offered: true },
      { name: 'Позиции товаров', offered: true },
      { name: 'Умный подбор слов', offered: true },
      { name: 'Умное описание товаров', offered: true },
    ],
  },
  {
    label: 'Управление продажами',
    expandable: true,
    details: [
      { name: 'Ежедневная динамика', offered: true },
      { name: 'Аналитика по товарам', offered: true },
      { name: 'Аналитика заказов возвратов и продаж', offered: true },
      { name: 'Аналитика финансовых показателей', offered: true },
      { name: 'Аналитика по складам', offered: true },
      { name: 'Аналитика по размерам', offered: true },
      { name: 'Планирование поставок', offered: true },
      { name: 'Потенциал и Упущенные заказы', offered: true },
      { name: 'ABC Анализ', offered: true },
      { name: 'Трекер позиций', offered: true },
      { name: 'Похожие товары', offered: true },
      { name: 'Управление ценами', offered: true },
      { name: 'Товары требующие внимания', offered: true },
      { name: 'Панель экспресс анализа бизнеса', offered: true },
      { name: 'Еженедельные отчеты', offered: true },
    ],
  },
  // {
  //   label: 'Реклама',
  //   expandable: true,
  //   details: [
  //     { name: "Проверка рекламных ставок", offered: true },
  //   ]
  // }
];

const proConstants = {
  title: 'Pro',
  recommend: 'РЕКОМЕНДУЕМ',
  dataLengthText: '30 дней',
  dataPeriod: '2 года',
  sections: detailArrayPro,
};

export const tariffsEnum = {
  BASIC: 1,
  EXPERT: 2,
  PRO: 3,
};

export const tariffsConstants = {
  1: {
    [tariffsEnum.BASIC]: {
      oldPrice: false,
      newPrice: 4900,
      textPrice: 'В МЕСЯЦ',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    [tariffsEnum.PRO]: {
      oldPrice: false,
      newPrice: 9900,
      textPrice: 'В МЕСЯЦ',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
  2: {
    [tariffsEnum.BASIC]: {
      oldPrice: 14700,
      newPrice: 13230,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 3 МЕСЯЦА',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    [tariffsEnum.PRO]: {
      oldPrice: 29700,
      newPrice: 26730,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 3 МЕСЯЦА',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
  3: {
    [tariffsEnum.BASIC]: {
      oldPrice: 29400,
      newPrice: 23520,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    [tariffsEnum.PRO]: {
      oldPrice: 59400,
      newPrice: 47520,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
  4: {
    [tariffsEnum.BASIC]: {
      oldPrice: 58800,
      newPrice: 41160,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    [tariffsEnum.PRO]: {
      oldPrice: 118800,
      newPrice: 83160,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
  5: {
    [tariffsEnum.BASIC]: {
      oldPrice: 117600,
      newPrice: 70560,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    [tariffsEnum.PRO]: {
      oldPrice: 237600,
      newPrice: 142560,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
};

export const detailArrayExtWB = [
  {
    label: 'Аналитика продаж',
    expandable: false,
    details: [
      { name: 'Товаров', offered: true },
      { name: 'Категорий', offered: true },
      { name: 'Брендов', offered: true },
      { name: 'Продавцов', offered: true },
      { name: 'Размеров', offered: true },
      { name: 'Цветов', offered: true },
      { name: 'Складов', offered: true },
    ],
  },
];

const extWBConstants = {
  title: 'Расширение',
  recommend: '🔥 СУПЕР-ПРЕДЛОЖЕНИЕ',
  dataWarning: 'только на сайте WB',
  sections: detailArrayExtWB,
};

export const tariffExtWB = {
  oldPrice: false,
  newPrice: 'Бесплатно',
  textPrice: 'В МЕСЯЦ',
  buttonText: 'Установить в Браузер',
  ...extWBConstants,
};
